@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Winter Minie';
  src: url('../public/font/Winter Minie.ttf') format('truetype');
}

* {
  font-family: 'Winter Minie', sans-serif;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink {
  0%, 100% { border-color: white }
  50% { border-color: transparent }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) translateX(0);
  }
  25% {
    transform: translateY(-10px) translateX(5px);
  }
  50% {
    transform: translateY(0) translateX(10px);
  }
  75% {
    transform: translateY(10px) translateX(5px);
  }
}

.typing-animation {
  display: inline-block;
  overflow: hidden;
  border-right: 2px solid;
  white-space: nowrap;
  animation: 
    typing 3.5s steps(40, end),
    blink .75s step-end infinite;
}

.skew-button {
  transform: skew(-15deg);
}

.counter-skew {
  display: inline-block;
  transform: skew(15deg);
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(168, 85, 247, 0.3) transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, rgba(167, 94, 255, 0.5), rgba(236, 72, 153, 0.5));
  border-radius: 20px;
}

.spiral-bg {
  position: fixed;
  inset: 0;
  margin: auto;
  width: 150vmax;
  height: 150vmax;
  background: conic-gradient(
    from 0deg at center,
    rgba(167, 94, 255, 0),
    rgba(236, 72, 153, 0.2),
    rgba(167, 94, 255, 0.4),
    rgba(250, 204, 21, 0.3),
    rgba(236, 72, 153, 0.2),
    rgba(167, 94, 255, 0)
  );
  animation: spin 20s linear infinite;
  opacity: 0.7;
  pointer-events: none;
  z-index: 0;
  transform-origin: center;
}

.menu-item {
  position: relative;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease;
}

.menu-item::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #A855F7;
  transition: width 0.3s ease;
}

.menu-item:hover::after,
.menu-item.active::after {
  width: 100%;
}

.menu-item.active {
  background-color: rgba(168, 85, 247, 0.3);
  border-radius: 20px;
}
